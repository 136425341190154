"use client"

import { ErrorPageBody } from "@/app/_components/ErrorPageBody"
import * as Sentry from "@sentry/nextjs"
import { useEffect } from "react"

export default function ErrorPage({
  error
}: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <div>
      <ErrorPageBody statusCode={500} />
    </div>
  )
}
